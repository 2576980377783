import { EntityId } from "../../../../features/entity/type"

export const LOGIN_ACTION_TYPE = "LOGIN_ACTION_TYPE"
export const LOGIN_REQ_ACTION_TYPE = "LOGIN_REQ_ACTION_TYPE"
export const CHANGE_PASSWORD_ACTION_TYPE = "CHANGE_PASSWORD_ACTION_TYPE"
export const CHANGE_PASSWORD_REQ_ACTION_TYPE = "CHANGE_PASSWORD_REQ_ACTION_TYPE"
export const LOGOUT_ACTION_TYPE = "LOGOUT_ACTION_TYPE"
const LOGFAIL_ACTION_TYPE = "LOGFAIL_ACTION_TYPE"
const SET_BACK_URL_ACTION_TYPE = "SET_BACK_URL_ACTION_TYPE"
const USER_CHANGE_PASSWORD_ACTION = "USER_CHANGE_PASSWORD_ACTION"
const USER_CHANGE_PASSWORD_FAIL_ACTION = "USER_CHANGE_PASSWORD_FAIL_ACTION"

const FAKING_TOKEN = "FOR_TEST_ONLY_FAKING_TOKEN"

interface ITESTINGFakingTokenAction {
  type: typeof FAKING_TOKEN
}

export interface IDecodedToken {
  exp: number
  nbf: number
  scope: string
  user_id: string
}

export interface ILoginRes extends IDecodedToken {
  token: string
}

export interface ILoginAction {
  type: typeof LOGIN_ACTION_TYPE
  logRes: ILoginRes
}

export interface ILoginRAction {
  type: typeof LOGIN_REQ_ACTION_TYPE
  login: string
  password: string
}

export interface IChangePasswordAction {
	type: typeof CHANGE_PASSWORD_ACTION_TYPE
	message: string
	status: string
  }

export interface IChangePasswordRAction {
	type: typeof USER_CHANGE_PASSWORD_FAIL_ACTION
	error: string
  }

interface ILogOuttAction {
  type: typeof LOGOUT_ACTION_TYPE
}

interface ILogFailtAction {
  type: typeof LOGFAIL_ACTION_TYPE
  message: string
}

interface ISetBackUrlAction {
  type: typeof SET_BACK_URL_ACTION_TYPE
  url: string
}

export function loginAction(logRes: ILoginRes): ILoginAction {
  return {
    type: LOGIN_ACTION_TYPE,
    logRes
  }
}

export function loginReqAction(login: string, password: string): ILoginRAction {
  return {
    type: LOGIN_REQ_ACTION_TYPE,
    login, password
  }
}

export function changePasswordAction(message: string, status: string): IChangePasswordAction {
	return {
	  type: CHANGE_PASSWORD_ACTION_TYPE,
	  message,
	  status
	}
  }

export function changePasswordReqAction(message: string, status: string): IChangePasswordAction {
	return {
	  type: CHANGE_PASSWORD_ACTION_TYPE,
	  message,
	  status
	}
  }

export function logOutAction(): ILogOuttAction {
  return {
    type: LOGOUT_ACTION_TYPE
  }
}

export function logFailAction(message: string): ILogFailtAction {
  return {
    type: LOGFAIL_ACTION_TYPE,
    message
  }
}

export function setBackUrlAction(url: string): ISetBackUrlAction {
  return {
    type: SET_BACK_URL_ACTION_TYPE,
    url
  }
}

type ActionTypes = ILoginAction | ILogOuttAction | 
  ILogFailtAction | ISetBackUrlAction | ILoginRAction | IChangePasswordAction | IChangePasswordRAction |
  ITESTINGFakingTokenAction

export interface IAuthState {
  current_user_id: null | EntityId
  token: null | string
  errorMessage: null | string
  backUrl: string
}

const initialState: IAuthState = {
  current_user_id: null,
  token: null,
  errorMessage: null,
  backUrl: "/"
}

export default (state = initialState, action: ActionTypes): IAuthState => {
  switch (action.type) {
    case LOGIN_ACTION_TYPE:
      return {
        ...state,
        current_user_id: action.logRes.user_id,
        token: action.logRes.token,
        errorMessage: null
      }

    case LOGOUT_ACTION_TYPE:
      if (!!state.current_user_id || !!state.token)
        return {
          ...state,
          current_user_id: null,
          token: null,
          errorMessage: null
        }
      break

	case USER_CHANGE_PASSWORD_FAIL_ACTION:
	return {
		...state,
		errorMessage: action.error
	}

    case LOGFAIL_ACTION_TYPE:
      return {
        ...state,
        errorMessage: action.message
      }

    case SET_BACK_URL_ACTION_TYPE:
      return {
        ...state,
        backUrl: action.url
      }

    case FAKING_TOKEN:
      return {
        ...state,
        token: "test fake tocken"
      }
  }
  return state
}