import React from 'react'
import { Container } from '@material-ui/core'

import ChangePasswordForm from './authentication/components/change-password'
export default () => {
  return <React.Fragment>
    <main className={"classes.content"}>
      <div className={"classes.toolbar"} />
      <Container maxWidth={false}
                 style={{marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0}}>
        <ChangePasswordForm />
      </Container>
    </main>
  </React.Fragment>
}