import React, { useEffect } from 'react'

import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { AppState } from '../../../store'
import {changePasswordAction, changePasswordReqAction} from '../reducers/store'
import ChangePasswordForm from './change-password-form'
import {useTranslation} from "react-i18next";

import {changePasswordUserAction} from "../../../../models/user/saga";

const mapStateToProps = ({ authentication }: AppState) => {
  return {
    authStore: authentication
  }
}

const mapDispatch = {
	changePasswordReqAction,
  changePasswordAction,
}

const connector = connect(mapStateToProps, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux

function ChangePassword(props: Props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()


  return <ChangePasswordForm errorMessage={props.authStore.errorMessage}
    onClick={(current_password, new_password, confirm_password) => {
		dispatch(changePasswordUserAction(current_password, new_password, confirm_password))
	}} />
}

const Wrapper = (props: Props) => (
  <ChangePassword {...props} />
)

export default connector(Wrapper)
